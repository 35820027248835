<template>
  <header class="header-global">
    <base-nav class="navbar-main" transparent type="" effect="light" expand>
      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand"></div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto mobile-only">
        <li class="nav-item">
          <router-link class="nav-link" to="/story"> My Story</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/skills"> Skills</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/examples"> Examples</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/schedule"> Schedule</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/rates"> Rates</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/message"> Contact</router-link>
        </li>
      </ul>
      <hr />
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://linkedin.com/in/jzych"
            target="_blank"
            data-toggle="tooltip"
            title="Follow me on LinkedIn"
          >
            <i class="fa fa-linkedin"></i>
            <span class="nav-link-inner--text d-lg-none">LinkedIn</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://twitter.com/jakubzych118"
            target="_blank"
            data-toggle="tooltip"
            title="Follow me on Twitter"
          >
            <i class="fa fa-twitter-square"></i>
            <span class="nav-link-inner--text d-lg-none">Twitter</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://github.com/Mr118/about-me"
            target="_blank"
            data-toggle="tooltip"
            title="Check me on Github"
          >
            <i class="fa fa-github"></i>
            <span class="nav-link-inner--text d-lg-none">Github</span>
          </a>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "../components/BaseNav";
import BaseDropdown from "../components/BaseDropdown";
import CloseButton from "../components/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  }
};
</script>
<style></style>
