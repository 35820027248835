<template>
  <div id="app">
    <div v-if="!loading">
    <no-ssr>
    <cookie-law
      theme="dark-lime"
      message="This website uses cookies to ensure smooth and secure experience."
    ></cookie-law>
    </no-ssr>
    </div>
    <router-view name="header"></router-view>
    <main>
      <!--  <transition @enter="enterEl" @leave="leaveEl"> -->
      <router-view />
      <!-- </transition> -->
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>
<script>
import CookieLaw from "vue-cookie-law";
import NoSSR from "vue-no-ssr";

export default {
  components: { CookieLaw, "no-ssr": NoSSR },
  data() {
   return {
     loading: true
   }
  },
  methods: {},
  mounted() {},
  updated() {},
     created () {
        this.$nextTick(function () {
           this.loading = false
       })
     }
};
</script>
<style></style>
