<template>
  <div class="profile-page">
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile" no-body style="padding-bottom: 50px">
          <div class="px-4">
            <NavigationButtons></NavigationButtons>
            <div class="text-center mt-5">
              <h1>Examples</h1>
              <h2 class="h6 font-weight-light">
                Some of IT projects I worked or work on. Find more at <a href="https://www.viamage.com" target="_blank">My Team's Website</a>!
              </h2>
            </div>
            <div class="mt-5 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-4" v-for="example in examples" v-bind:key="example.slug">
                      <a @click="enableModal(example.slug)">
                        <img
                          class="img-fluid rounded-circle shadow example-img"
                          lazy=""
                          :data-src="example.cover"
                          :src="example.cover"
                          v-b-popover.hover.top="'Click for details'"
                          :title="example.title"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
    <div v-for="example in examples">
      <modal :show.sync="modals[example.slug]">
        <h6 slot="header" class="modal-title" id="modal-title-notification">
          {{ example.title }}
        </h6>

        <div class="py-3 text-justify">
          <div v-html="example.description"></div>
        </div>

        <template slot="footer">
          <a
            v-if="example.link"
            class="pull-left btn pull-left btn-link"
            :href="example.link"
            target="_blank"
          >
            Go to Website
          </a>
          <base-button
            type="link"
            class="ml-auto"
            @click="disableModal(example.slug)"
          >
            Close
          </base-button>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
require("es6-promise").polyfill();
import axios from "axios";
import BPopover from "bootstrap-vue/es/directives/popover/popover";
import NavigationButtons from "../layout/NavigationButtons";
import Modal from "../components/Modal";
import Vue from "vue";
import titleMixin from "../mixins/title-mixin";
import descriptionMixin from "../mixins/description-mixin";
import urlMixin from "../mixins/url-mixin";
import metadata from "../metadata";

export default {
  mixins: [titleMixin, descriptionMixin, urlMixin],
  directives: {
    BPopover
  },
  components: { NavigationButtons, Modal },
  data() {
    return {
      apiUrl: "https://api.jakubzych.com/v1/examples",
      examples: [],
      modals: []
    };
  },
  title() {
    return metadata().examples.title;
  },
  description() {
    return metadata().examples.description;
  },
  url(){
    return metadata().examples.url;
  },
  methods: {
    enableModal(slug) {
      Vue.set(this.modals, slug, true);
    },
    disableModal(slug) {
      Vue.set(this.modals, slug, false);
    },
    getExamplesData() {
      let component = this;
      axios
        .get(this.apiUrl, {
          params: {}
        })
        .then(response => {
          component.examples = response.data;
          component.examples.forEach(function(example) {
            Vue.set(component.modals, example.slug, false);
          });
        })
        .catch(response => {
          console.log(response);
        });
    }
  },
  mounted() {
    this.getExamplesData();
  }
};
</script>
<style></style>
