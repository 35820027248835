function getTitle(vm) {
  // components can simply provide a `title` option
  // which can be either a string or a function
  const { title } = vm.$options;
  if (title) {
    return typeof title === "function" ? title.call(vm) : title;
  }
}

const titleMixin = {
  mounted() {
    const title = getTitle(this);
    if (title) {
      document.title = title;
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", title);
      document
        .querySelector('meta[name="twitter:title"]')
        .setAttribute("content", title);
      document
        .querySelector('meta[name="application-name"]')
        .setAttribute("content", title);
    }
  }
};

// `VUE_ENV` can be injected with `webpack.DefinePlugin`
export default titleMixin;
