<template>
  <div class="profile-page">
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile" no-body>
          <div class="px-4">
            <NavigationButtons></NavigationButtons>
            <div class="text-center mt-5">
              <h1>Rates</h1>
              <h2 class="h6 font-weight-light">
                Default per hour rates for small consultations and bigger
                contracts
              </h2>
            </div>
            <div class="mt-5 py-5 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <p>
                    Rates below are my base rates for the fields listed. They
                    can be adjusted depending on your exact requirements.
                  </p>
                  <p>
                    Monthly and yearly rates are approximate - may differ
                    according to month's length.
                  </p>
                  <p>Final invoice is always issued per exact hours worked.</p>
                  <base-dropdown>
                    <base-button
                      slot="title"
                      type="primary"
                      class="dropdown-toggle"
                    >
                      Currency: {{ currency }}
                    </base-button>
                    <a class="dropdown-item" v-on:click="changeCurrency('EUR')"
                      >EUR</a
                    >
                    <a class="dropdown-item" v-on:click="changeCurrency('USD')"
                      >USD</a
                    >
                    <a class="dropdown-item" v-on:click="changeCurrency('GBP')"
                      >GBP</a
                    >
                    <a class="dropdown-item" v-on:click="changeCurrency('PLN')"
                      >PLN</a
                    >
                  </base-dropdown>
                  <base-dropdown>
                    <base-button
                      slot="title"
                      type="primary"
                      class="dropdown-toggle"
                    >
                      Period: {{ period }}
                    </base-button>
                    <a class="dropdown-item" v-on:click="changePeriod('hour')"
                      >Hour</a
                    >
                    <a class="dropdown-item" v-on:click="changePeriod('month')"
                      >Month</a
                    >
                    <a class="dropdown-item" v-on:click="changePeriod('year')"
                      >Year</a
                    >
                  </base-dropdown>
                  <base-button
                    v-if="period === 'month' || period === 'year'"
                    @click.native="modals.monthlyHoursModal = true"
                    >Change Monthly Hours ({{ monthlyHours }})
                  </base-button>

                  <div class="mt-5 py-5 border-top">
                    <div class="row justify-content-center">
                      <div class="col-lg-9">
                        <table class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Rate</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="rate in rates">
                              <td>{{ rate.name }}</td>
                              <td>
                                {{ calculateRate(rate.rate) }} {{ currency }} /
                                {{ period }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
    <modal :show.sync="modals.monthlyHoursModal">
      <template slot="header">
        <h5 class="modal-title" id="monthlyHoursLabel">Modal title</h5>
      </template>
      <div>
        <p>At how many approximate monthly hours are we looking at?</p>
        <base-input v-model="monthlyHours"></base-input>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.monthlyHoursModal = false"
          >Close</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import NavigationButtons from "../layout/NavigationButtons";
import BaseDropdown from "../components/BaseDropdown.vue";
import Modal from "../components/Modal.vue";
import axios from "axios";
import titleMixin from "../mixins/title-mixin";
import descriptionMixin from "../mixins/description-mixin";
import urlMixin from "../mixins/url-mixin";
import metadata from "../metadata";

export default {
  mixins: [titleMixin, descriptionMixin, urlMixin],
  components: { NavigationButtons, BaseDropdown, Modal },
  title() {
    return metadata().rates.title;
  },
  description() {
    return metadata().rates.description;
  },
  url(){
    return metadata().rates.url;
  },
  data() {
    return {
      modals: {
        monthlyHoursModal: false
      },
      currency: "EUR",
      period: "hour",
      baseCurrency: "EUR",
      monthlyHours: 64,
      baseRates: [
        {
          name: "Standard Rate",
          rate: 30.0
        },
        {
          name: "Web Applications Backend",
          rate: 30.0
        },
        {
          name: "Mobile Applications",
          rate: 35.0
        },
        {
          name: "RealTime Solutions Development",
          rate: 25.0
        },
        {
          name: "System Administration",
          rate: 30.0
        },
        {
          name: "Apps Performance Boosting",
          rate: 30.0
        }
      ],
      rates: []
    };
  },
  methods: {
    calculateRate(rate) {
      if (this.period === "hour") {
        return rate;
      }
      if (this.period === "month") {
        this.modals.monthlyHours = false;
        return rate * this.monthlyHours;
      }
      if (this.period === "year") {
        this.modals.yearlyHours = false;
        return rate * (this.monthlyHours * 12);
      }
    },
    changePeriod(period) {
      this.period = period;
    },
    changeCurrency(currency) {
      let component = this;
      let ratio = 1;
      axios
        .get(
          "https://exchange.viamage.com/ratio?base=" +
            component.baseCurrency +
            "&target=" +
            currency,
          {
            params: {}
          }
        )
        .then(response => {
          ratio = response.data;
          let result = [];
          component.baseRates.forEach(function(rate) {
            result.push({
              name: rate.name,
              rate: Math.ceil(rate.rate * ratio)
            });
          });
          component.rates = result;
          component.currency = currency;
        })
        .catch(response => {
          console.log(response);
        });
    }
  },
  mounted() {
    this.rates = this.baseRates;
  }
};
</script>
<style></style>
