<template>
  <div class="profile-page">
    <section class="section ">
      <div class="container">
        <card shadow class="card-profile" no-body>
          <div class="px-4">
            <NavigationButtons></NavigationButtons>
            <div class="text-center mt-5">
              <h2 class="h1">Jakub Zych</h2>
              <h1 class="h6 font-weight-light">Experienced IT Consultant</h1>
            </div>
            <div class="mt-5 py-5 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <p>Hello!</p>
                  <p>
                    My name is Jakub. I'm one of the co-owners of
                    <a href="https://www.viamage.com" target="_blank"
                      >Viamage Limited</a
                    >
                    and I provide consultancy and development services within
                    areas of web and mobile applications development, databases
                    management, backend development, real time technologies,
                    fast search solutions, performance analysis and more.
                  </p>
                  <p>
                    This website will provide you with core information about
                    services I provide and am experienced in, it will allow you
                    to order them, check my availability and read more about my
                    IT skills I can offer.
                  </p>
                  <p>
                    If you are looking for someone who will help you with
                    implementing services like real time features or fast search
                    for big data, design application architecture, review
                    network topology, assess your technology stack and provide
                    best and most stable solutions available, you are in a good
                    place.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
    <script type="application/ld+json" v-html="JSON.stringify(microdata)"></script>
  </div>
</template>
<script>
import NavigationButtons from "../layout/NavigationButtons";
import titleMixin from "../mixins/title-mixin";
import descriptionMixin from "../mixins/description-mixin";
import urlMixin from "../mixins/url-mixin";
import metadata from "../metadata";

export default {
  mixins: [titleMixin, descriptionMixin, urlMixin],
  title() {
    return metadata().main.title;
  },
  description() {
    return metadata().main.description;
  },
  url(){
    return metadata().main.url;
  },
  data() {
    return {
      microdata: {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Skills",
            item: "https://www.jakubzych.com/skills"
          },
          {
            "@type": "ListItem",
            position: 2,
            name: "Examples",
            item: "https://www.jakubzych.com/examples"
          },
          {
            "@type": "ListItem",
            position: 3,
            name: "Contact",
            item: "https://www.jakubzych.com/message"
          },
          {
            "@type": "ListItem",
            position: 4,
            name: "My Story",
            item: "https://www.jakubzych.com/story"
          },
          {
            "@type": "ListItem",
            position: 5,
            name: "Rates",
            item: "https://www.jakubzych.com/rates"
          },
          {
            "@type": "ListItem",
            position: 6,
            name: "Schedule",
            item: "https://www.jakubzych.com/schedule"
          }
        ]
      }
    };
  },
  components: { NavigationButtons },
  mounted() {}
};
</script>
<style></style>
