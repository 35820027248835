function getDescription(vm) {
  // components can simply provide a `title` option
  // which can be either a string or a function
  const { description } = vm.$options;
  if (description) {
    return typeof description === "function"
      ? description.call(vm)
      : description;
  }
}

const descriptionMixin = {
  mounted() {
    const description = getDescription(this);
    if (description) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", description);
      document
        .querySelector('meta[name="twitter:description"]')
        .setAttribute("content", description);
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", description);
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", description);
    }
  }
};

// `VUE_ENV` can be injected with `webpack.DefinePlugin`
export default descriptionMixin;
