<template>
  <div class="profile-page">
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile" no-body>
          <div class="px-4">
            <NavigationButtons></NavigationButtons>
            <div class="text-center mt-5">
              <h1>404</h1>
              <h2 class="h6 font-weight-light">
                Sorry, there is no such page :(
              </h2>
            </div>
            <div class="mt-5 py-5 border-top text-justify">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="row text-center">
                    <router-link
                      to="/"
                      class="btn btn-default"
                      style="margin: 0 auto;"
                      ><span>Try here!</span></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import NavigationButtons from "../layout/NavigationButtons";
import titleMixin from "../mixins/title-mixin";
import descriptionMixin from "../mixins/description-mixin";
import urlMixin from "../mixins/url-mixin";
import metadata from "../metadata";

export default {
  mixins: [titleMixin, descriptionMixin, urlMixin],
  components: { NavigationButtons },
  data() {
    return {

    };
  },
  title() {
    return metadata().notFound.title;
  },
  description() {
    return metadata().notFound.description;
  },
  url() {
    return metadata().notFound.url;
  }
};
</script>
<style></style>
