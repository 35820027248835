export default () => {
  return {
    main: {
      title: "Jakub Zych - Experienced IT Consultant",
      description:
        "Web and Mobile Applications, Databases Management, Backend Development, Real Time Technologies, Fast Search Services, Performance Analysis",
      image: "https://www.jakubzych.com/dist/img/theme/wizard.png",
      keywords:
        "experienced it consultant, web developer, node developer, php developer, js developer, system administrator",
      url: "/"
    },
    examples: {
      title: "Examples | Jakub Zych - Experienced IT Consultant",
      description: "Projects, applications and solutions examples",
      image: "https://www.jakubzych.com/dist/img/theme/wizard.png",
      keywords:
        "experienced it consultant, web developer, node developer, php developer, js developer, system administrator",
      url: "/examples"
    },
    skills: {
      title: "Skills | Jakub Zych - Experienced IT Consultant",
      description:
        "List of my areas of expertise with examples and descriptions",
      image: "https://www.jakubzych.com/dist/img/theme/wizard.png",
      keywords:
        "experienced it consultant, web developer, node developer, php developer, js developer, system administrator",
      url: "/skills"
    },
    story: {
      title: "My Story | Jakub Zych - Experienced IT Consultant",
      description: "Short biography, to let you know my background better",
      image: "https://www.jakubzych.com/dist/img/theme/wizard.png",
      keywords:
        "experienced it consultant, web developer, node developer, php developer, js developer, system administrator",
      url: "/story"
    },
    rates: {
      title: "Rates | Jakub Zych - Experienced IT Consultant",
      description:
        "Transparent basic rates, which I start all negotiations with",
      image: "https://www.jakubzych.com/dist/img/theme/wizard.png",
      keywords:
        "experienced it consultant, web developer, node developer, php developer, js developer, system administrator",
      url: "/rates"
    },
    schedule: {
      title: "Schedule | Jakub Zych - Experienced IT Consultant",
      description: "Find out what amount of time I can offer for your project",
      image: "https://www.jakubzych.com/dist/img/theme/wizard.png",
      keywords:
        "experienced it consultant, web developer, node developer, php developer, js developer, system administrator",
      url: "/schedule"
    },
    message: {
      title: "Contact | Jakub Zych - Experienced IT Consultant",
      description:
        "Write me a message, request services, let's talk about details!",
      image: "https://www.jakubzych.com/dist/img/theme/wizard.png",
      keywords:
        "experienced it consultant, web developer, node developer, php developer, js developer, system administrator",
      url: "/message"
    },
    notFound: {
      title: "Not Found | Jakub Zych - Experienced IT Consultant",
      description: "Sorry, but that page was not found!",
      image: "https://www.jakubzych.com/dist/img/theme/wizard.png",
      keywords:
        "experienced it consultant, web developer, node developer, php developer, js developer, system administrator",
      url: "/404"
    }
  };
};
