<template>
  <div class="profile-page">
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile" no-body>
          <div class="px-4">
            <NavigationButtons></NavigationButtons>
            <div class="text-center mt-5">
              <h1>Message Me</h1>
              <h2 class="h6 font-weight-light">
                To contact me, please use form below or any of my social media
                profiles.
              </h2>
            </div>
            <div class="mt-5 py-5 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <form v-if="!sent" @submit.prevent="sendEmail">
                    <base-input
                      v-model="name"
                      name="name"
                      alternative
                      placeholder="Your name"
                      type="text"
                      addon-left-icon="fa fa-user"
                    ></base-input>
                    <div
                      role="alert"
                      class="alert alert-warning alert-dismissible"
                      v-if="showNameError"
                    >
                      <span class="alert-inner--icon"
                        ><i class="fa fa-exclamation-circle"></i></span
                      ><span class="alert-inner--text"
                        ><span>
                          Please provide your name (or nickname at least) to
                          send the message.</span
                        ></span
                      >
                      <button
                        type="button"
                        v-on:click="showNameError = false"
                        aria-label="Close"
                        class="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <base-input
                      v-model="email"
                      name="email"
                      alternative
                      placeholder="Your e-mail"
                      type="email"
                      addon-left-icon="fa fa-envelope"
                    ></base-input>
                    <div
                      role="alert"
                      class="alert alert-warning alert-dismissible"
                      v-if="showEmailError"
                    >
                      <span class="alert-inner--icon"
                        ><i class="fa fa-exclamation-circle"></i></span
                      ><span class="alert-inner--text"
                        ><span>
                          Please provide your e-mail to send the message.</span
                        ></span
                      >
                      <button
                        type="button"
                        v-on:click="showEmailError = false"
                        aria-label="Close"
                        class="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <textarea
                      v-model="message"
                      name="message"
                      class="form-control form-control-alternative"
                      rows="3"
                      placeholder="Your message"
                      style="margin-bottom: 1rem;"
                    ></textarea>
                    <div
                      role="alert"
                      class="alert alert-warning alert-dismissible"
                      v-if="showContentError"
                    >
                      <span class="alert-inner--icon"
                        ><i class="fa fa-exclamation-circle"></i></span
                      ><span class="alert-inner--text"
                        ><span>
                          You need to provide some content to send the
                          message.</span
                        ></span
                      >
                      <button
                        type="button"
                        v-on:click="showContentError = false"
                        aria-label="Close"
                        class="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 text-justify">
                        <base-checkbox class="mb-3" v-model="showCalendar">
                          I want to schedule a call
                        </base-checkbox>
                        <br />
                        <div v-if="showCalendar">
                          <p>
                            Great! What is your preferred time and timezone?
                          </p>
                          <div class="row">
                            <div class="col-lg-4">
                              <base-input
                                v-model="preferredTime"
                                name="preferredTime"
                                alternative
                                placeholder="eg. 16:00-18:00"
                                type="text"
                                addon-left-icon="fa fa-clock-o"
                              ></base-input>
                            </div>
                            <div class="col-lg-8">
                              <div
                                class="form-group input-group input-group-alternative"
                              >
                                <input
                                  list="timezonesList"
                                  class="form-control"
                                  v-model="timezone"
                                  placeholder="Please provide your timezone"
                                />
                                <datalist id="timezonesList">
                                  <option
                                    v-for="tz in timezones"
                                    :value="tz.text"
                                    >{{ tz.text }}
                                  </option>
                                </datalist>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                    <button class="btn btn-primary" type="submit">
                      <i v-if="showAnimation" class="fa fa-spinner fa-spin"></i>
                      <i v-if="!showAnimation" class="fa fa-send"></i>
                      Send
                    </button>
                    <br /><br />
                    <div
                      role="alert"
                      class="alert alert-warning alert-dismissible"
                      v-if="error"
                    >
                      <span class="alert-inner--icon"
                        ><i class="fa fa-exclamation-circle"></i></span
                      ><span class="alert-inner--text"
                        ><span>
                          <strong>Oops!</strong> Seems that my e-mail provider
                          is temporarily down, please try again in a moment or
                          <a href="mailto:jakub@viamage.com"
                            >contact me by e-mail</a
                          >.</span
                        ></span
                      >
                      <button
                        type="button"
                        v-on:click="error = false"
                        aria-label="Close"
                        class="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div
                      role="alert"
                      class="alert alert-warning alert-dismissible"
                      v-if="throttleError"
                    >
                      <span class="alert-inner--icon"
                        ><i class="fa fa-exclamation-circle"></i></span
                      ><span class="alert-inner--text"
                        ><span>
                          <strong>Oops!</strong> It looks like I received enough
                          messages from your IP already.</span
                        ></span
                      >
                      <button
                        type="button"
                        v-on:click="error = false"
                        aria-label="Close"
                        class="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  </form>

                  <div
                    role="alert"
                    class="alert alert-success alert-dismissible"
                    v-if="sent"
                  >
                    <span class="alert-inner--icon"
                      ><i class="ni ni-like-2"></i></span
                    ><span class="alert-inner--text"
                      ><span
                        ><strong>Thank you for your message!</strong> I will
                        respond as soon as possible.</span
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import BPopover from "bootstrap-vue/es/directives/popover/popover";
import NavigationButtons from "../layout/NavigationButtons";
import BaseCheckbox from "../components/BaseCheckbox";
import titleMixin from "../mixins/title-mixin";
import descriptionMixin from "../mixins/description-mixin";
import urlMixin from "../mixins/url-mixin";
import metadata from "../metadata";

export default {
  mixins: [titleMixin, descriptionMixin, urlMixin],
  directives: {
    BPopover
  },
  components: { NavigationButtons, BaseCheckbox },
  title() {
    return metadata().message.title;
  },
  description() {
    return metadata().message.description;
  },
  url(){
    return metadata().message.url;
  },
  data() {
    return {
      apiUrl: "/api/internal/mail",
      name: "",
      email: "",
      message: "",
      csrf: "",
      sent: false,
      error: false,
      throttleError: false,
      showAnimation: false,
      showEmailError: false,
      showContentError: false,
      showNameError: false,
      showCalendar: false,
      stopExecution: false,
      preferredTime: "",
      timezone: "",
      timezones: [
        {
          text: "(GMT-12:00) International Date Line West",
          value: "Etc/GMT+12"
        },
        { text: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
        { text: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
        { text: "(GMT-09:00) Alaska", value: "US/Alaska" },
        {
          text: "(GMT-08:00) Pacific Time (US & Canada)",
          value: "America/Los_Angeles"
        },
        {
          text: "(GMT-08:00) Tijuana, Baja California",
          value: "America/Tijuana"
        },
        { text: "(GMT-07:00) Arizona", value: "US/Arizona" },
        {
          text: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
          value: "America/Chihuahua"
        },
        {
          text: "(GMT-07:00) Mountain Time (US & Canada)",
          value: "US/Mountain"
        },
        { text: "(GMT-06:00) Central America", value: "America/Managua" },
        {
          text: "(GMT-06:00) Central Time (US & Canada)",
          value: "US/Central"
        },
        {
          text: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
          value: "America/Mexico_City"
        },
        { text: "(GMT-06:00) Saskatchewan", value: "Canada/Saskatchewan" },
        {
          text: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
          value: "America/Bogota"
        },
        {
          text: "(GMT-05:00) Eastern Time (US & Canada)",
          value: "US/Eastern"
        },
        { text: "(GMT-05:00) Indiana (East)", value: "US/East-Indiana" },
        {
          text: "(GMT-04:00) Atlantic Time (Canada)",
          value: "Canada/Atlantic"
        },
        { text: "(GMT-04:00) Caracas, La Paz", value: "America/Caracas" },
        { text: "(GMT-04:00) Manaus", value: "America/Manaus" },
        { text: "(GMT-04:00) Santiago", value: "America/Santiago" },
        { text: "(GMT-03:30) Newfoundland", value: "Canada/Newfoundland" },
        { text: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
        {
          text: "(GMT-03:00) Buenos Aires, Georgetown",
          value: "America/Argentina/Buenos_Aires"
        },
        { text: "(GMT-03:00) Greenland", value: "America/Godthab" },
        { text: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
        { text: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
        { text: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
        { text: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
        {
          text: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
          value: "Africa/Casablanca"
        },
        {
          text:
            "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
          value: "Etc/Greenwich"
        },
        {
          text: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
          value: "Europe/Amsterdam"
        },
        {
          text: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
          value: "Europe/Belgrade"
        },
        {
          text: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
          value: "Europe/Brussels"
        },
        {
          text: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
          value: "Europe/Sarajevo"
        },
        { text: "(GMT+01:00) West Central Africa", value: "Africa/Lagos" },
        { text: "(GMT+02:00) Amman", value: "Asia/Amman" },
        {
          text: "(GMT+02:00) Athens, Bucharest, Istanbul",
          value: "Europe/Athens"
        },
        { text: "(GMT+02:00) Beirut", value: "Asia/Beirut" },
        { text: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
        { text: "(GMT+02:00) Harare, Pretoria", value: "Africa/Harare" },
        {
          text: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
          value: "Europe/Helsinki"
        },
        { text: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
        { text: "(GMT+02:00) Minsk", value: "Europe/Minsk" },
        { text: "(GMT+02:00) Windhoek", value: "Africa/Windhoek" },
        { text: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "Asia/Kuwait" },
        {
          text: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
          value: "Europe/Moscow"
        },
        { text: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
        { text: "(GMT+03:00) Tbilisi", value: "Asia/Tbilisi" },
        { text: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
        { text: "(GMT+04:00) Abu Dhabi, Muscat", value: "Asia/Muscat" },
        { text: "(GMT+04:00) Baku", value: "Asia/Baku" },
        { text: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
        { text: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
        { text: "(GMT+05:00) Yekaterinburg", value: "Asia/Yekaterinburg" },
        {
          text: "(GMT+05:00) Islamabad, Karachi, Tashkent",
          value: "Asia/Karachi"
        },
        {
          text: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
          value: "Asia/Calcutta"
        },
        { text: "(GMT+05:30) Sri Jayawardenapura", value: "Asia/Calcutta" },
        { text: "(GMT+05:45) Kathmandu", value: "Asia/Katmandu" },
        { text: "(GMT+06:00) Almaty, Novosibirsk", value: "Asia/Almaty" },
        { text: "(GMT+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
        { text: "(GMT+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
        { text: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
        { text: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
        {
          text: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
          value: "Asia/Hong_Kong"
        },
        {
          text: "(GMT+08:00) Kuala Lumpur, Singapore",
          value: "Asia/Kuala_Lumpur"
        },
        { text: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
        { text: "(GMT+08:00) Perth", value: "Australia/Perth" },
        { text: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
        { text: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
        { text: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
        { text: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
        { text: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
        { text: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
        { text: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
        {
          text: "(GMT+10:00) Canberra, Melbourne, Sydney",
          value: "Australia/Canberra"
        },
        { text: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
        { text: "(GMT+10:00) Guam, Port Moresby", value: "Pacific/Guam" },
        { text: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
        {
          text: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
          value: "Asia/Magadan"
        },
        {
          text: "(GMT+12:00) Auckland, Wellington",
          value: "Pacific/Auckland"
        },
        {
          text: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
          value: "Pacific/Fiji"
        },
        { text: "(GMT+13:00) Nuku'alofa", value: "Pacific/Tongatapu" }
      ]
    };
  },
  methods: {
    validate() {
      this.showNameError = !this.name;
      if (this.showNameError) {
        return false;
      }
      this.showEmailError = !this.email;
      if (this.showEmailError) {
        return false;
      }
      this.showContentError = !this.message;
      if (this.showContentError) {
        return false;
      }

      return true;
    },
    sendEmail() {
      if (!this.validate()) {
        return;
      }
      this.showAnimation = true;

      this.csrf = document
        .querySelector('meta[name="csrf"]')
        .getAttribute("content");
      axios
        .create({
          withCredentials: true,
          headers: { "X-CSRF-TOKEN": this.csrf }
        })
        .post(this.apiUrl, {
          name: this.name,
          email: this.email,
          message: this.message,
          wantMeeting: this.showCalendar,
          meetingAt: this.preferredTime,
          timezone: this.timezone,
          _csrf: this.csrf
        })
        .then(response => {
          if (response.data === "Sent") {
            this.sent = true;
          } else {
            this.error = true;
          }
          this.showAnimation = false;
        })
        .catch(error => {
          if (error.response.status === 429) {
            this.throttleError = true;
          } else {
            this.error = true;
          }

          this.showAnimation = false;
        });
    },
    reset() {
      this.timezone = {};
    }
  },
  mounted() {}
};
</script>
<style></style>
