<template>
  <div class="profile-page">
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile" no-body>
          <div class="px-4">
            <NavigationButtons></NavigationButtons>
            <div class="text-center mt-5">
              <h1>Skills</h1>
              <h2 class="h6 font-weight-light">
                IT skills with highest proficiency levels
              </h2>
            </div>
            <div class="mt-5 py-5 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <p>Below you will find some of my skills.</p>
                  <p>
                    I've skipped some obvious ones that I cannot prove with
                    commercial experience <br />(like Java or Go, which I used
                    for personal projects only)
                  </p>
                  <p>
                    If you are interested in a full list, feel free to visit my
                    <a href="https://linkedin/in/jzych">LinkedIn</a> profile.
                  </p>
                  <div class="mt-5 py-5 border-top ">
                    <div class="row justify-content-center">
                      <div class="col-lg-12">
                        <div v-for="skill in skills">
                          <table class="table table-bordered">
                            <thead
                              v-on:click="toggleSkillSlug(skill.slug)"
                              style="cursor: pointer;"
                              :id="skill.slug"
                            >
                              <tr>
                                <th colspan="2">
                                  {{ skill.title }}
                                  <span
                                    class="pull-right"
                                    v-if="!display[skill.slug]"
                                    ><i class="fa fa-chevron-down"></i
                                  ></span>
                                  <span
                                    class="pull-right"
                                    v-if="display[skill.slug]"
                                    ><i class="fa fa-chevron-up"></i
                                  ></span>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="display[skill.slug]">
                              <tr>
                                <td>
                                  Years of experience<br />
                                  <small>Commercial only</small>
                                </td>
                                <td>
                                  {{ getExperienceYears(skill.year_started) }}
                                </td>
                              </tr>
                              <tr v-for="row in skill.content">
                                <td v-html="row.key"></td>
                                <td v-html="row.value"></td>
                              </tr>
                            </tbody>
                          </table>

                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
    <script type="application/ld+json" v-html="JSON.stringify(microdata)"></script>
  </div>
</template>
<script>
require("es6-promise").polyfill();
import axios from "axios";
import Vue from "vue";
import NavigationButtons from "../layout/NavigationButtons";
import titleMixin from "../mixins/title-mixin";
import descriptionMixin from "../mixins/description-mixin";
import urlMixin from "../mixins/url-mixin";
import metadata from "../metadata";

export default {
  mixins: [titleMixin, descriptionMixin, urlMixin],
  components: { NavigationButtons },
  title() {
    return metadata().skills.title;
  },
  description() {
    return metadata().skills.description;
  },
  url() {
    return metadata().skills.url;
  },
  data() {
    return {
      apiUrl: "https://api.jakubzych.com/v1/skills",
      skills: [],
      display: [],
      microdata: {
        "@context": "http://schema.org",
        "@type": "ItemList",
        url: "https://www.jakubzych.com/skills",
        numberOfItems: 6,
        itemListElement: [
          {
            "@context": "http://schema.org",
            "@type": "ListItem",
            position: 1,
            name: "JavaScript / Node.JS",
            description: "",
            url: "https://www.jakubzych.com/skills#javascript-nodejs"
          },
          {
            "@context": "http://schema.org",
            "@type": "ListItem",
            position: 2,
            name: "PHP7+",
            description: "",
            url: "https://www.jakubzych.com/skills#php7"
          },
          {
            "@context": "http://schema.org",
            "@type": "ListItem",
            position: 3,
            name: "SQL Databases",
            description: "",
            url: "https://www.jakubzych.com/skills#sql-databases"
          },
          {
            "@context": "http://schema.org",
            "@type": "ListItem",
            position: 4,
            name: "NoSQL Databases",
            description: "",
            url: "https://www.jakubzych.com/skills#nosql-databases"
          },
          {
            "@context": "http://schema.org",
            "@type": "ListItem",
            position: 5,
            name: "System Administration",
            description: "",
            url: "https://www.jakubzych.com/skills#system-administration"
          },
          {
            "@context": "http://schema.org",
            "@type": "ListItem",
            position: 6,
            name: "VoIP",
            description: "",
            url: "https://www.jakubzych.com/skills#voip"
          }
        ]
      }
    };
  },
  methods: {
    getExperienceYears(yearStarted) {
      return 2019 - yearStarted;
    },
    toggleSkillSlug(slug) {
      Vue.set(this.display, slug, !this.display[slug]);
    },
    getSkillsData() {
      let component = this;
      axios
        .get(this.apiUrl, {
          params: {}
        })
        .then(response => {
          component.skills = response.data.skills;
          component.skills.forEach(function(skill) {
            Vue.set(component.display, skill.slug, false);
          });
        })
        .catch(response => {
          console.log(response);
        });
    }
  },
  mounted() {
    this.getSkillsData();
  }
};
</script>
<style>
.hidden {
  display: none;
}
</style>
