function getTitle(vm) {
  // components can simply provide a `url` option
  // which can be either a string or a function
  const { url } = vm.$options;
  if (url) {
    return typeof url === "function" ? url.call(vm) : url;
  }
}

const urlMixin = {
  mounted() {
    const url = getTitle(this);
    if (url) {
      document.url = url;
      document
        .querySelector('meta[property="og:url"]')
        .setAttribute("content", "https://www.jakubzych.com" + url);
      document
        .querySelector('link[rel="canonical"]')
        .setAttribute("href", "https://www.jakubzych.com" + url);
    }
  }
};

// `VUE_ENV` can be injected with `webpack.DefinePlugin`
export default urlMixin;
