<template>
  <div class="profile-page">
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile" no-body>
          <div class="px-4">
            <NavigationButtons></NavigationButtons>

            <div class="text-center mt-5">
              <h1>Schedule</h1>
              <span class="font-weight-light"></span>
              <h2 class="h6 font-weight-light">
                Check my availability and order consultations
              </h2>
            </div>

            <div class="mt-5 py-5 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="h6 mt-4">
                    <p>
                      I am very strict about my schedule and I provide contract
                      services for a fixed amount of hours per month.
                    </p>
                    <p>
                      This allows me to guarantee my availability within agreed
                      hours, commit to given tasks properly, maintain my time in
                      a stable manner and provide solutions exactly within
                      quoted time range.
                    </p>
                    <p>
                      Below you will find information about time I can offer, if
                      you want to discuss it in more detail, feel free to
                      <router-link to="/message">message me</router-link>
                      .
                    </p>
                  </div>

                  <div class="mt-5 py-5 border-top text-center">
                    <div class="row justify-content-center">
                      <div class="col-lg-12">
                        <div
                          class="row justify-content-center"
                          v-for="month in months"
                        >
                          <div class="col-lg-9">
                            <base-progress
                              type="primary"
                              :value="month.takenPercentage"
                              :label="month.name"
                            ></base-progress>
                            <p>{{ month.available }} hours available</p>
                          </div>
                        </div>
                      </div>
                      <router-link
                        to="/message"
                        class="btn btn-default"
                        style="margin: 0 auto;"
                      ><span>Get my services!</span></router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
require("es6-promise").polyfill();
import axios from "axios";
import NavigationButtons from "../layout/NavigationButtons";
import titleMixin from "../mixins/title-mixin";
import descriptionMixin from "../mixins/description-mixin";
import urlMixin from "../mixins/url-mixin";
import metadata from "../metadata";

export default {
  mixins: [titleMixin, descriptionMixin, urlMixin],
  components: { NavigationButtons },
  title() {
    return metadata().schedule.title;
  },
  description() {
    return metadata().schedule.description;
  },url(){
    return metadata().schedule.url;
  },
  data() {
    return {
      apiUrl: "https://api.jakubzych.com/v1/hours",
      months: []
    };
  },
  methods: {
    getMonthsData() {
      let component = this;
      axios
        .get(this.apiUrl, {
          params: {}
        })
        .then(response => {
          component.months = response.data;
        })
        .catch(response => {
          console.log(response);
        });
    }
  },
  mounted() {
    this.getMonthsData();
  }
};
</script>
<style></style>
