// router.js
import Vue from "vue";
import Router from "vue-router";
import AppHeader from "../layout/AppHeader";
import AppFooter from "../layout/AppFooter";
import Profile from "../views/Profile.vue";
import Story from "../views/Story.vue";
import Schedule from "../views/Schedule.vue";
import Examples from "../views/Examples.vue";
import Message from "../views/Message.vue";
import Skills from "../views/Skills.vue";
import Rates from "../views/Rates.vue";
import NotFound from "../views/NotFound.vue";
import metadata from "../metadata";

Vue.use(Router);

export function createRouter() {
  return new Router({
    mode: "history",
    hashbang: false,
    routes: [
      {
        path: "/",
        name: "profile",
        components: {
          header: AppHeader,
          default: Profile,
          footer: AppFooter
        },
        meta: metadata().main
      },
      {
        path: "/story",
        name: "story",
        components: {
          header: AppHeader,
          default: Story,
          footer: AppFooter
        },
        meta: metadata().story
      },
      {
        path: "/examples",
        name: "examples",
        components: {
          header: AppHeader,
          default: Examples,
          footer: AppFooter
        },
        meta: metadata().examples
      },
      {
        path: "/schedule",
        name: "schedule",
        components: {
          header: AppHeader,
          default: Schedule,
          footer: AppFooter
        },
        meta: metadata().schedule
      },
      {
        path: "/message",
        name: "message",
        components: {
          header: AppHeader,
          default: Message,
          footer: AppFooter
        },
        meta: metadata().message
      },
      {
        path: "/skills",
        name: "skills",
        components: {
          header: AppHeader,
          default: Skills,
          footer: AppFooter
        },
        meta: metadata().skills
      },
      {
        path: "/rates",
        name: "rates",
        components: {
          header: AppHeader,
          default: Rates,
          footer: AppFooter
        },
        meta: metadata().rates
      },
      {
        path: "/404",
        name: "notfound",
        components: {
          header: AppHeader,
          default: NotFound,
          footer: AppFooter
        },
        meta: metadata().notFound
      },
      {
        path: "*",
        redirect: "/404"
      }
    ],
    scrollBehavior: to => {
      if (to.hash) {
        return { selector: to.hash };
      } else {
        return { x: 0, y: 0 };
      }
    }
  });
}
