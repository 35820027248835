// main.js
import Vue from "vue";
import App from "./App.vue";
import Argon from "./plugins/jzbc-init";
import { createRouter } from "./router/router.js";

export function createApp() {
  Vue.use(Argon);
  const router = createRouter();
  const app = new Vue({
    router,
    render: h => h(App)
  });

  return { app, router };
}
