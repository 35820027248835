<template>
  <div id="navigation">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="row card-profile-actions text-center desktop-only">
          <div class="col-lg-4">
            <router-link
              title="Read my story"
              to="story"
              class="btn btn-default btn-sm"
              ><span>My Story</span></router-link
            >
          </div>
          <div class="col-lg-4">
            <router-link
              title="Check my skills"
              to="skills"
              class="btn btn-default btn-sm"
              ><span>Skills</span></router-link
            >
          </div>
          <div class="col-lg-4">
            <router-link
              title="See examples of my work"
              to="examples"
              class="btn btn-default btn-sm"
              ><span>Examples</span></router-link
            >
          </div>
        </div>
      </div>

      <div class="col-lg-2">
        <div class="card-profile-image">
          <a href="#">
            <router-link to="/"
              ><img
                alt="Jakub Zych"
                src="dist/img/theme/wizard128.jpg"
                class="rounded-circle"
            /></router-link>
          </a>
        </div>
      </div>

      <div class="col-lg-5">
        <div class="row card-profile-actions text-center desktop-only">
          <div class="col-lg-4">
            <router-link
              title="Check my schedule"
              to="schedule"
              class="btn btn-default btn-sm"
              ><span>Schedule</span></router-link
            >
          </div>
          <div class="col-lg-4">
            <router-link
              title="See my default rates"
              to="rates"
              class="btn btn-default btn-sm"
              ><span>Rates</span></router-link
            >
          </div>
          <div class="col-lg-4">
            <router-link
              title="Send me a message"
              to="message"
              class="btn btn-default btn-sm"
              ><span>Contact</span></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavigationButtons",
  props: ["url"]
};
</script>
<style></style>
