<template>
  <div class="profile-page">
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile" no-body>
          <div class="px-4">
            <NavigationButtons></NavigationButtons>
            <div class="text-center mt-5">
              <h1>My Story</h1>
              <h2 class="h6 font-weight-light">
                My background and road in IT so far
              </h2>
            </div>
            <div class="mt-5 py-5 border-top text-justify">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <p>
                    My story with IT began in 90s, when as a kid I received
                    Commodore64 with a Commodore Basic manual, which was the
                    first programming language I've learned, being 9 years old.
                  </p>
                  <p>
                    This was the end of the era of Commodore and Amiga and soon
                    enough school where my father worked received devices for
                    its IT class. With my father being IT teacher, I've been
                    regularly staying after school to help him with Windows NT4,
                    Small Business Server software and school network
                    management.
                  </p>
                  <p>
                    My highschool was a kind-of break from IT, but I underwent
                    full return during my studies. I started to work as a system
                    administrator and database specialist for a VoIP software
                    company, where I spent more than 2 years of learning,
                    working with people from all around the world and polishing
                    my IT skills.
                  </p>

                  <p>
                    In 2013 I co-founded C-Call.eu as a start up and in 2014 I
                    left my job. C-Call.eu quickly stopped to be just a start up
                    and number of projects and customers have occupied lots of
                    my time, so I didn't really looked for another "stable",
                    "9to5" job and started to put even more effort in this
                    company.
                  </p>

                  <p>
                    In August 2014 I passed lots of my responsibilities in
                    C-Call.eu to my trusted partners, started cooperation with
                    waski.net VoIP operator and Alizee Telecom as a system
                    administrator. These cooperations quickly escalated when it
                    was clear that both companies require custom software for
                    their business. This is when Keios Solutions was converted
                    into IT services agency, hired some talented people and
                    started developing.
                  </p>

                  <p>
                    For over four years under this label I have offered services
                    of me and my team in web applications development. We
                    specialized in VoIP portals development, integration and
                    custom e-commerce solutions (such as printing business
                    e-commerce, voip e-commerce, mobile recharge e-commerce,
                    hostel booking etc). We have created a huge stack of plugins
                    and tools for OctoberCMS and there was no e-commerce type
                    that was outside of our scope.
                  </p>

                  <p>
                    In September 2017 we have joined Viamage initiative, I have
                    become an executive director there, being responsible for
                    web development projects of that company.
                  </p>

                  <p>
                    While our teams are doing great job in Viamage, in 2019 I
                    decided to personally aid some external companies with my
                    knowledge as an IT consultant, providing architecture
                    guidelines, backend code, insight and, when required,
                    in-house code to make development and maintenance smooth and
                    fast.
                  </p>
                  <br />
                  <div class="row text-center">
                    <router-link
                      to="/skills"
                      class="btn btn-default"
                      style="margin: 0 auto;"
                      ><span>Check my skills!</span></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import NavigationButtons from "../layout/NavigationButtons";
import titleMixin from "../mixins/title-mixin";
import descriptionMixin from "../mixins/description-mixin";
import urlMixin from "../mixins/url-mixin";
import metadata from "../metadata";

export default {
  mixins: [titleMixin, descriptionMixin, urlMixin],
  components: { NavigationButtons },
  title() {
    return metadata().story.title;
  },
  description() {
    return metadata().story.description;
  },
  url(){
    return metadata().story.url;
  }
};
</script>
<style></style>
